<template>
  <v-app id="app" app-data="true">
    <p>sdfsdf</p>
    <v-card
      style="width: 95%; margin: 20px; margin-left: auto; margin-right: auto; height: 60px;}"
      v-if="isLoggedIn"
    >
      <v-list-item style="margin-top: -5px" three-line>
        <v-list-item-content>
          <div class="overline mb-4">
            Raspberry - {{ userData.rpi_id }} | {{ userData.shop }}
          </div>
        </v-list-item-content>
      </v-list-item>
    </v-card>

    <span v-else>TESET</span>

    <router-view />

    <v-card-text style="height: 100px; position: relative">
      <v-btn
        @click.stop="drawer = !drawer"
        absolute
        dark
        fab
        right
        bottom
        style="position: fixed; margin-bottom: 90px"
        v-if="isLoggedIn"
      >
        <v-icon dark>mdi-format-list-bulleted-square</v-icon>
      </v-btn>
    </v-card-text>

    <v-navigation-drawer v-if="isLoggedIn" v-model="drawer" absolute temporary>
      <v-list-item>
        <v-list-item-avatar>
          <v-icon>donut_small</v-icon>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title>{{ userData.shop }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list dense>
        <v-list-item v-for="item in items" :key="item.title" link>
          <span @click.prevent="this.$router.push(item.url)">
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <!-- <router-link
            style="text-decoration: none; color: inherit"
            :to="item.url"
          > -->
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </span>
          <!-- </router-link> -->
        </v-list-item>
        <v-list-item @click="logout" link>
          <v-list-item-icon>
            <v-icon>logout</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Выход</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </v-app>
</template>

<script>
import axios from "axios";
export default {
  computed: {
    isLoggedIn: function () {
      return this.$store.getters.isLoggedIn;
    },
    userData: function () {
      return JSON.parse(window.localStorage.getItem("user_game"));
    },
  },
  data() {
    return {
      drawer: null,
      items: [
        { title: "Статус", icon: "opacity", url: "/" },
        { title: "История установок", icon: "history", url: "/history" },
        { title: "Инструкция", icon: "contact_support", url: "/instruction" },
      ],
    };
  },
  methods: {
    logout: function () {
      this.$store.dispatch("logout").then(() => {
        this.$router.push("/");
      });
    },
  },
  created: function () {
    this.$http.interceptors.response.use(undefined, function (err) {
      return new Promise(function () {
        if (err.status === 401 && err.config && !err.config.__isRetryRequest) {
          this.$store.dispatch("logout");
        }
        throw err;
      });
    });

    if (this.$store.getters.isLoggedIn) {
      axios
        .post("/backend/validate_token.php", {
          token: this.$store.getters.getToken,
        })
        .then(() => {
          console.log("valid token")
        })
        .catch(() => {
          console.log("invalid token")
          this.$store.dispatch("logout");
          this.$router.push("/");
        });
    }
  },
};
</script>