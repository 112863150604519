<template>
  <span style="margin-top: 50px; margin-right: 20px; margin-left: 20px;">
     <v-card class="mx-auto mt-12" max-width="450" style="background: #ffffffa3">
      <v-col>
        <v-toolbar-title style="text-align: center">авторизация</v-toolbar-title>
        <p v-if="statusErrorWindow" class="error_message">{{ error_message }}</p>
        <v-form ref="form">
          <v-text-field
            v-model="rpiID"
            :rules="[rules.required]"
            type= "text"
            name="input-10-1"
            label="номер RPI"
            hint="3 цифры на вашем устройстве"
            @click:append="showErrorMessage = !showErrorMessage"
            counter
          ></v-text-field>

          <v-text-field
            v-model="password"
            :append-icon="showErrorMessage ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="[rules.required, rules.min, rules.max]"
            :type="showErrorMessage ? 'text' : 'password'"
            name="input-10-1"
            label="ключ доступа"
            hint="Всего 6 символов"
            @click:append="showErrorMessage = !showErrorMessage"
            counter
          ></v-text-field>
        </v-form>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="login()">войти</v-btn>
        </v-card-actions>
      </v-col>
    </v-card>
  </span>
</template>

<script>
// import axios from "axios";
export default {
  data: () => ({
    statusErrorWindow: false,
    errorMessage: '',
    showErrorMessage: false,
    statusLoginMessage: false,
    password: "",
    rpiID: "",
    rules: {
      required: value => !!value || "Обязательно.",
      min: v => v.length >= 6 || "Ключ доступа не менее 6 символов",
      max: v => v.length <= 6 || "Вы ввели более 6 символов"
    }
  }),
  methods: {
    login() {
    //   axios
        // .post("/apiGamification/login.php", { auth_key: this.password })
        // .then(({ data }) => {
        //   console.log("auth info: " + data.message);
        //   this.statusLoginMessage = true;
        // })
        // .catch(({ response }) => {
        //   alert(response.data.message);
        // });

      if(this.password){
        let password = this.password 
        let rpi_id = this.rpiID

        this.$store.dispatch('login', {password, rpi_id})
       .then(() => 
       console.log("AUTH "+this.$store.getters.isLoggedIn)
       )
       .catch(
         err => console.log(err),
        this.statusErrorWindow = true,
         this.error_message = "Вы ввели не верный ключ, попробйте еще раз."
         )
      }else{
        this.statusErrorWindow = true
         this.error_message = "Сначала введите свой ключ."
      }
              setTimeout(() => {
          this.statusErrorWindow = false
          this.error_message = ''
        }, 2000);

    }
  },
  computed: {}
};
</script>

