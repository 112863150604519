<template>
  <div class="home">
    <span style="margin-top: 50px; margin-right: 20px; margin-left: 20px">
      <v-card
        class="mx-auto mt-12"
        max-width="450"
        style="background: #ffffffa3"
      >
        <v-col>
          <v-toolbar-title style="text-align: center"
            >авторизация</v-toolbar-title
          >
          <v-btn
            color="blue-grey"
            class="ma-2 white--text"
            @click.prevent="redirectToLink()"
          >
            Авторизация Workplace
            <v-icon right dark> fingerprint </v-icon>
          </v-btn>
        </v-col>
      </v-card>
    </span>
  </div>
</template>

<script>
export default {
  name: "Home",
  computed: {
    isLoggedIn: function () {
      return this.$store.getters.isLoggedIn;
    },
    userData: function () {
      return JSON.parse(window.localStorage.getItem("user"));
    },
  },
  methods: {
    redirectToLink() {
      window.location = "https://capf.comfy.ua/auth/web-auth/authorize?client_id=digital-services&response_type=code&redirect_uri=http%3A%2F%2Fdev.digital-services.com.ua/login/%2F";
    },
  },
};
</script>
